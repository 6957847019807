import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError } from 'rxjs';
import { ApiService } from 'src/app/admin-portal/core/services/api.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environment/environment';

@Injectable({
  providedIn: 'root',
})
export class OpenPositionService {
  baseUrl = environment.apiUrl;
  searchQuery = new BehaviorSubject<any>('');
  constructor(private http: HttpClient, private apiservice: ApiService) {}

  emitSearchQuery(arg: string) {
    this.searchQuery.next(arg);
  }

  createOpenPosition(formData: any): Observable<any> {
    const path = `${this.baseUrl}/open-position`;
    return this.apiservice.post(path, formData);
  }

  getResume(resumePath: string): Observable<Blob> {
    const apiUrl = `${this.baseUrl}/files/${resumePath}`;
    return this.http.get(apiUrl, { responseType: 'blob' });
  }

  getOpenPositionList(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: 'ASC' | 'DESC' = 'DESC',
    accountId?: string,
    costCenterId?: string,
    experienceRange?: string,
    role?: string
  ): Observable<any> {
    let path = `${this.baseUrl}/open-position/list?page=${pageNumber}&limit=${limit}&search=${search}&sortBy=${sortBy}&orderBy=${orderBy}`;

    if (accountId) {
      path += `&accountID=${accountId}`;
    }
    if (costCenterId) {
      path += `&cost_centerID=${costCenterId}`;
    }
    if (experienceRange) {
      path += `&experienceRange=${experienceRange}`;
    }
    if (role) {
      path += `&role=${role}`;
    }

    return this.apiservice.get(path);
  }

  getOpenPositionListCustomer(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: 'ASC' | 'DESC' = 'DESC',
    costCenterId?: string,
    accountId?: string,
    projectId?: string,
    experienceRange?: string,
    role?: string
  ): Observable<any> {
    let path = `${this.baseUrl}/open-position/list?page=${pageNumber}&limit=${limit}&search=${search}&sortBy=${sortBy}&orderBy=${orderBy}`;

    if (accountId) {
      path += `&accountID=${accountId}`;
    }
    if (projectId) {
      path += `&projectId=${projectId}`;
    }
    if (costCenterId) {
      path += `&cost_centerID=${costCenterId}`;
    }
    if (experienceRange) {
      path += `&experienceRange=${experienceRange}`;
    }
    if (role) {
      path += `&role=${role}`;
    }

    return this.apiservice.get(path);
  }

  getOpenPositionById(id: string): Observable<any> {
    const path = `${this.baseUrl}/open-position/${id}`;
    return this.apiservice.get(path);
  }

  getExperianceRange(){
    const path = `${this.baseUrl}/master/expirence-ranges`
    return this.apiservice.get(path)
  }

  updateOpenPosition(PositionData: any): Observable<any> {
    const url = `${this.baseUrl}/open-position`;
    return this.apiservice.put(url, PositionData).pipe(
      catchError((error: any) => {
        console.error('Error updating Open Position:', error);
        throw error;
      })
    );
  }

  getCandidateList(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: '' | 'ASC' | 'DESC' = '',
    positionID: string
  ): Observable<any> {
    const path = `${this.baseUrl}/candidate/list?positionID=${positionID}&page=${pageNumber}&limit=${limit}&search=${search}&sortBy=${sortBy}&orderBy=${orderBy}`;
    return this.apiservice.get(path);
  }

  closeOpenPosition(positionID: string): Observable<any> {
    const path = `${this.baseUrl}/open-position/close?positionID=${positionID}`;
    return this.apiservice.post(path, { positionID });
  }
}
